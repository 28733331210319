import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import Layout from 'shared/AnotherCourses/Layout';
import { HeaderWithPromotion } from 'shared/AnotherCourses/component/HeaderPage';
import { useBreadcrumbSchema } from 'shared/AnotherCourses/hooks/useBreadcrumbSchema';

const ListCourse = ({ location }) => {
  const breadCrumbList = useBreadcrumbSchema({ slug: 'sat' });
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister={false}
    >
      <MySEO
        slug={location.pathname}
        breadCrumbSchema={breadCrumbList}
        h1="SAT"
        featureImg="zbELDlK3QGL4KBJzdRrQ"
        title="Tổng hợp khóa học luyện thi SAT đảm bảo đầu ra tại DOL ENGLISH"
        description="DOL English là trung tâm luyện thi SAT ở TpHCM uy tín và tốt nhất, áp dụng Linearthinking giúp cấp tốc đạt mục tiêu SAT từ 1300 - 1500+ trong vòng 9 tuần"
      />
      <HeaderWithPromotion level="SAT" />
      <Layout level="SAT" />
    </MainLayout>
  );
};

export default React.memo(ListCourse);
